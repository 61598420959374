import { AxiosResponse } from "axios";
import ApiService from "./ApiService";
import { SearchModuleFilter } from "../filterData/searchModuleFilter";

const API_URL = "modules";

class ModuleService {
  public static getModules(
    searchModuleFilter: SearchModuleFilter
  ): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/filter", searchModuleFilter);
  }

  public static getModuleById(id): Promise<AxiosResponse> {
    return ApiService.get(API_URL + "/" + id);
  }

  public static createModule(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL, formData);
  }

  public static updateModule(formData: FormData): Promise<AxiosResponse> {
    return ApiService.post(API_URL + "/edit", formData);
  }

  public static deleteModule(id): Promise<AxiosResponse> {
    return ApiService.delete(API_URL + "/" + id);
  }
}

export default ModuleService;
