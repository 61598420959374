
import { computed, defineComponent, onMounted, watch, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/store/useAuthStore";
import { IModuleContent } from "@/core/data/moduleContent";
import { IModule } from "@/core/data/module";
import { UploadProps } from "element-plus";
// import YouTube from "vue3-youtube";
import ModuleService from "@/core/services/ModuleService";
import Swal from "sweetalert2";
import { IModuleAttachment } from "@/core/data/moduleAttachment";

interface IUploadFile {
  file: any;
  uid: number;
}

export default defineComponent({
  name: "",
  // components: { YouTube },
  setup(props, { emit }) {
    const route = useRoute();
    const youtubeRef = ref();
    const router = useRouter();
    const authStore = useAuthStore();
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const content_order = ref(1);
    const acceptedTypes = ref(".jpeg,.jpg,.png,.pdf");
    const maxFileSize = ref(5 * 1024 * 1024); // Specify the maximum file size in bytes (e.g., 5MB)
    const moduleFileTempList = ref<IUploadFile[]>([]);
    const uploadRef = ref();
    const fileList = ref([]);
    const deleteModuleContentIdList = ref<any[]>([]);
    const deleteAttachmentIdList = ref<any[]>([]);
    const moduleAttachmentbaseURL = ref(
      process.env.VUE_APP_API_URL + "modules/attachments/"
    );
    const moduleData = ref<IModule>({
      id: null,
      title: "",
      description: "",
      type: "",
      contents: [],
      created_by: null,
      attachments: []
    });

    const moduleContents = ref<Array<IModuleContent>>([]);

    const moduleContent = ref<IModuleContent>({
      id: null,
      title: "",
      description: "",
      url: "",
      content_order: 1,
    });

    const rules = computed(() => {
      return {
        title: [
          {
            required: true,
            message: "Please input module title.",
            trigger: "blur",
          },
          {
            max: 255,
            message: "Module title must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Please input module description.",
            trigger: "blur",
          },
          {
            max: 255,
            message:
              "Module description must be no longer than 255 characters.",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "Please select module type.",
            trigger: "change",
          },
        ],
      };
    });

    // start::Add documents
    const handlePreview = (uploadFile) => {
      if (uploadFile) {
        const isAcceptedType = acceptedTypes.value
          .split(",")
          .some((type) => uploadFile.raw.name.toLowerCase().endsWith(type));
        const isSizeValid = uploadFile.size <= maxFileSize.value;

        if (!isAcceptedType) {
          Swal.fire({
            title: "Warning",
            text: "Only .jpeg, .jpg, .png, .pdf file types are supported.",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }

        if (!isSizeValid) {
          Swal.fire({
            title: "Warning",
            text:
              " Maximum file size is " +
              maxFileSize.value / (1024 * 1024) +
              "MB",
            icon: "warning",
            buttonsStyling: false,
            confirmButtonText: "Confirm",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        }
        if (isAcceptedType && isSizeValid) {
          let reader = new FileReader();
          reader.readAsDataURL(uploadFile.raw);
          reader.onload = (e) => {
            moduleFileTempList.value.push({
              file: uploadFile.raw,
              uid: uploadFile.uid,
            });
          };
        } else {
          uploadRef.value.handleRemove(uploadFile);
        }
      }
    };

    const handleRemove: UploadProps["onRemove"] = (file, uploadFiles) => {
      let index = moduleFileTempList.value.findIndex(
        (obj) => obj.uid === file.uid
      );
      if (index != -1) {
        moduleFileTempList.value.splice(index, 1);
      }
    };

    // end::Add documents

    const onSubmit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          var formData = new FormData();

          moduleFileTempList.value.forEach((moduleFile) => {
            formData.append("attachments[]", moduleFile.file);
          });

          moduleContents.value.forEach((moduleContent) => {
            formData.append("moduleContents[]", JSON.stringify(moduleContent));
          });
          formData.append("moduleData", JSON.stringify(moduleData.value));

          //new module
          if (moduleData.value.id == null) {
            ModuleService.createModule(formData).then((response) => {
              loading.value = false;
              Swal.fire({
                text: "Save complete",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              authStore.setModuleDataChange(true);
              router.push({
                name: "View Module",
                params: { id: response.data },
              });
            });
          } else {
            deleteAttachmentIdList.value.forEach((attachmentId) => {
              formData.append("delete_attachment_id_list[]", attachmentId);
            });
            deleteModuleContentIdList.value.forEach((moduleContentId) => {
              formData.append(
                "delete_module_content_id_list[]",
                moduleContentId
              );
            });
            ModuleService.updateModule(formData).then((response) => {
              loading.value = false;
              Swal.fire({
                text: "Save complete",
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
              authStore.setModuleDataChange(true);
              router.push({
                name: "View Module",
                params: { id: moduleData.value.id },
              });
            });
          }
        } else {
          return false;
        }
      });
    };

    // watch(
    //   () => moduleList.value.modules?.length,
    //   (newValue, oldValue) => {
    //     moduleData.value.title = "";
    //     moduleData.value.description = "";
    //     moduleData.value.url = "";
    //   }
    // );

    const addModule = () => {
      if (moduleContent.value.url == "") {
        Swal.fire({
          title: "Warning",
          text: "Please input content url.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Confirm",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } else if (moduleContent.value.title == "") {
        Swal.fire({
          title: "Warning",
          text: "Please input content title.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Confirm",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } else if (moduleContent.value.description == "") {
        Swal.fire({
          title: "Warning",
          text: "Please input content description.",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Confirm",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } else {
        moduleContents.value.push({
          id: null,
          title: moduleContent.value.title,
          description: moduleContent.value.description,
          url: moduleContent.value.url,
          content_order: content_order.value,
        });

        content_order.value++;

        moduleContent.value = {
          id: null,
          title: "",
          description: "",
          url: "",
          content_order: content_order.value,
        };
      }
    };

    const initModule = () => {
      ModuleService.getModuleById(route.params.id).then((response) => {
        moduleData.value = response.data;

        moduleData.value.contents?.forEach((content) => {
          moduleContents.value.push(content);
        });
      });
    };

    const deleteModule = (moduleContent) => {
      let deleteIndex = moduleContents.value.findIndex(
        (obj) => obj === moduleContent
      );

      if (moduleContents.value[deleteIndex].id != null) {
        deleteModuleContentIdList.value.push(
          moduleContents.value[deleteIndex].id
        );
      }

      moduleContents.value.splice(deleteIndex, 1);

      content_order.value--;
    };

    const deleteAttachment = (attachment: IModuleAttachment) => {
      deleteAttachmentIdList.value.push(attachment.id);
      let deleteAttachmentIndex = moduleData.value.attachments!.findIndex(
        (obj) => obj.id === attachment.id
      );

      moduleData.value.attachments!.splice(deleteAttachmentIndex, 1);
    };

    const goBack = () => {
      router.back();
    };

    onMounted(() => {
      if (route.params.id != undefined) {
        initModule();
      }
    });

    return {
      youtubeRef,
      moduleContent,
      moduleContents,
      moduleData,
      rules,
      loading,
      onSubmit,
      deleteModule,
      goBack,
      addModule,
      formRef,
      handlePreview,
      acceptedTypes,
      fileList,
      handleRemove,
      deleteAttachment,
      moduleAttachmentbaseURL,
      moduleFileTempList
    };
  },
});
